import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Card, Row, Col, Modal, Form, Select, Tooltip } from "antd";
import {
  CloseOutlined,
  RightSquareOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  GET_PRINTERS_REQUESTING,
  ADD_PRINTER_MODAL_HIDE,
  ADD_PRINTER_MODAL_SHOW,
  ADD_PRINTER_REQUESTING,
  REMOVE_PRINTER_REQUESTING,
  GET_VERSIONS_REQUESTING,
  CLEAR_PRINTERLIST,
} from "../../constants";

import "./List.css";
import dentaformPng from "./Structo_DentaForm109.png";
import veloxPng from "./Structo_Velox.png";
import vncScreenshot1 from "./vnc_screenshot_1.jpg";
import vncScreenshot2 from "./vnc_screenshot_2.jpg";

const { Meta } = Card;
const { Option } = Select;
const { confirm } = Modal;

class List extends React.Component {
  static propTypes = {
    printerType: PropTypes.string,
    printerList: PropTypes.array,
    versions: PropTypes.array,
    error: PropTypes.string,
    removePrinter: PropTypes.func,
    handleOK: PropTypes.func,
    showModal: PropTypes.func,
    hideModal: PropTypes.func,
    addModalVisible: PropTypes.bool,
    addModalLoading: PropTypes.bool,
    clearPrinters: PropTypes.func,
    getPrinters: PropTypes.func,
    getVersions: PropTypes.func,
    toastMessage: PropTypes.string,
  };

  componentDidMount() {
    this.props.clearPrinters();
    this.props.getPrinters(this.props.printerType);
    this.props.getVersions(this.props.printerType);
  }

  vncToPrinter(stage, name) {
    Modal.info({
      title: "VNC Session",
      content: (
        <div>
          <p>
            Upon clicking <strong>OK</strong> you will be brought to the VNC
            connection page
          </p>
          <p>
            <img
              src={vncScreenshot1}
              style={{ width: "70%" }}
              alt="vncScreenshot1"
            ></img>
          </p>
          <p>
            Click on the <strong>Connect</strong> button, then type in the
            password <strong>structo123</strong>
          </p>
          <p>
            <img
              src={vncScreenshot2}
              style={{ width: "70%" }}
              alt="vncScreenshot2"
            ></img>
          </p>
        </div>
      ),
      okCancel: true,
      onOk() {
        if (stage === "staging")
          window.open(
            `https://${name}.scratch.dev.structo.io?resize=scale`,
            "_blank"
          );
        else if (stage === "production")
          window.open(
            `https://${name}.scratch.structo3d.com?resize=scale`,
            "_blank"
          );
      },
    });
  }

  removeConfirmation(printerPayload) {
    confirm({
      title: `Do you Want to delete ${printerPayload.name}?`,
      icon: <ExclamationCircleOutlined />,
      content: "There is no going back. Really.",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        this.props.removePrinter(printerPayload);
      },
      onCancel: () => {
        console.log("Cancelled");
      },
    });
  }

  getPrinterPng() {
    switch (this.props.printerType) {
      case "dentaform":
        return <img alt={this.props.printerType} src={dentaformPng} />;
      case "velox":
        return <img alt={this.props.printerType} src={veloxPng} />;
      default:
        return null;
    }
  }

  render() {
    return (
      <div>
        {this.props.error && (
          <Row gutter={[8, 8]} justify="center">
            <div style={{ color: "#ff4d4f" }}>{this.props.error}</div>
          </Row>
        )}
        {!this.props.error && this.props.printerList.length > 0 && (
          <Row className="site-card-wrapper" gutter={[8, 8]}>
            {this.props.printerList.map((element, i) => {
              return (
                <Col key={i}>
                  <Card
                    key={i}
                    style={{ width: 300 }}
                    cover={this.getPrinterPng()}
                    actions={[
                      <Tooltip key="vnc" title="Connect via VNC">
                        <RightSquareOutlined
                          onClick={(e) =>
                            this.vncToPrinter(element.stage, element.name)
                          }
                        />
                      </Tooltip>,
                      <Tooltip key="remove" title="Delete Printer">
                        <CloseOutlined
                          key="remove"
                          onClick={(e) => this.removeConfirmation(element)}
                        />
                      </Tooltip>,
                    ]}
                  >
                    <Meta
                      title={element.name}
                      description={
                        <div>
                          <section>
                            <b>Software Version:</b> {element.softwareVersion}
                          </section>
                          <section>
                            <b>Created At:</b>{" "}
                            {new Date(element.createdAt).toTimeString()}
                          </section>
                          <section>
                            <b>Updated At:</b>{" "}
                            {new Date(element.createdAt).toTimeString()}
                          </section>
                        </div>
                      }
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>
        )}
        <Button
          type="primary"
          className="add-new-printer"
          onClick={this.props.showModal}
        >
          <PlusOutlined />
          Create New {capitalize(this.props.printerType)} Printer
        </Button>

        <Modal
          title={`Create New ${capitalize(this.props.printerType)} Printer`}
          visible={this.props.addModalVisible}
          onCancel={this.props.hideModal}
          footer={[
            <Button key="back" onClick={this.props.hideModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.props.addModalLoading}
              form="create-printer-form"
              htmlType="submit"
            >
              Submit
            </Button>,
          ]}
        >
          <p>Select the fields below:</p>
          <Form
            id="create-printer-form"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(values) => {
              this.props.handleOK(values, this.props.printerType);
            }}
          >
            <Form.Item
              name="softwareVersion"
              label="SoftwareVersion"
              rules={[{ required: true }]}
            >
              <Select placeholder="Select a software version" allowClear>
                {this.props.versions.map((v) => (
                  <Option key={v} value={v}>
                    {v}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

function clearPrinters() {
  return { type: CLEAR_PRINTERLIST };
}

function getPrinters(printerType) {
  return { type: GET_PRINTERS_REQUESTING, printerType: printerType };
}

function removePrinter(printerPayload) {
  const { name, type } = printerPayload;
  return { type: REMOVE_PRINTER_REQUESTING, name, printerType: type };
}

function getVersions(printerType) {
  return { type: GET_VERSIONS_REQUESTING, printerType: printerType };
}

function handleOK(values, printerType) {
  return {
    type: ADD_PRINTER_REQUESTING,
    printerType: printerType,
    values: values,
  };
}

function showModal() {
  return {
    type: ADD_PRINTER_MODAL_SHOW,
  };
}

function hideModal() {
  return {
    type: ADD_PRINTER_MODAL_HIDE,
  };
}

const mapStateToProps = (state, ownprops) => ({
  printerList: state.printerList.items,
  versions: state.printerList.versions,
  error: state.printerList.error,
  addModalVisible: state.printerList.addModalVisible,
  addModalLoading: state.printerList.addModalLoading,
  toastMessage: state.toast.message,
  printerType: ownprops.printerType,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearPrinters,
      getPrinters,
      getVersions,
      removePrinter,
      handleOK,
      showModal,
      hideModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
