export const NOOP = "NOOP";

export const AUTHENTICATION_REQUESTING = "AUTHENTICATION_REQUESTING";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";
export const AUTHENTICATION_LOGOUT = "AUTHENTICATION_LOGOUT";

export const GOOGLE_AUTH_REQUESTING = "GOOGLE_AUTH_REQUESTING";

export const CLEAR_PRINTERLIST = "CLEAR_PRINTERLIST";
export const GET_PRINTERS_REQUESTING = "GET_PRINTERS_REQUESTING";
export const GET_PRINTERS_SUCCESS = "GET_PRINTERS_SUCCESS";
export const GET_PRINTERS_ERROR = "GET_PRINTERS_ERROR";

export const GET_VERSIONS_REQUESTING = "GET_VERSIONS_REQUESTING";
export const GET_VERSIONS_SUCCESS = "GET_VERSIONS_SUCCESS";
export const GET_VERSIONS_ERROR = "GET_VERSIONS_ERROR";

export const ADD_PRINTER_MODAL_SHOW = "ADD_PRINTER_MODAL_SHOW";
export const ADD_PRINTER_MODAL_HIDE = "ADD_PRINTER_MODAL_HIDE";

export const ADD_PRINTER_REQUESTING = "ADD_PRINTER_REQUESTING";
export const ADD_PRINTER_SUCCESS = "ADD_PRINTER_SUCCESS";
export const ADD_PRINTER_ERROR = "ADD_PRINTER_ERROR";

export const REMOVE_PRINTER_REQUESTING = "REMOVE_PRINTER_REQUESTING";
export const REMOVE_PRINTER_SUCCESS = "REMOVE_PRINTER_SUCCESS";
export const REMOVE_PRINTER_ERROR = "REMOVE_PRINTER_ERROR";
