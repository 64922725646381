import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class PrivateRoute extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool,
    component: PropTypes.elementType,
  };

  render() {
    const { authenticated, component: InnerComponent, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          return authenticated ? (
            <InnerComponent {...rest} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.authentication,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
