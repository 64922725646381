import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import {
  AUTHENTICATION_REQUESTING,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_LOGOUT,
  AUTHENTICATION_ERROR,
  GET_PRINTERS_ERROR,
  GET_PRINTERS_SUCCESS,
  GET_PRINTERS_REQUESTING,
  ADD_PRINTER_REQUESTING,
  ADD_PRINTER_MODAL_SHOW,
  ADD_PRINTER_MODAL_HIDE,
  ADD_PRINTER_SUCCESS,
  ADD_PRINTER_ERROR,
  REMOVE_PRINTER_SUCCESS,
  REMOVE_PRINTER_ERROR,
  GET_VERSIONS_ERROR,
  GET_VERSIONS_SUCCESS,
  CLEAR_PRINTERLIST,
} from "./constants";

function authentication(
  state = {
    authenticated: false,
    requesting: false,
    successful: false,
    token: "",
    errors: [],
  },
  action
) {
  switch (action.type) {
    case AUTHENTICATION_REQUESTING:
      return {
        ...state,
        requesting: true,
      };
    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
        requesting: false,
        authenticated: true,
        token: action.response.token,
      };
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        requesting: false,
        authenticated: false,
      };
    case AUTHENTICATION_LOGOUT:
      return {
        ...state,
        authenticated: false,
      };
    default:
      return state;
  }
}

function printerList(
  state = {
    items: [],
    versions: [],
    error: null,
    addModalVisible: false,
    addModalLoading: false,
    removeLoading: false,
  },
  action
) {
  switch (action.type) {
    case CLEAR_PRINTERLIST:
      return {
        ...state,
        items: [],
      };
    case GET_PRINTERS_REQUESTING:
      return {
        ...state,
        error: null,
      };
    case GET_PRINTERS_SUCCESS:
      return {
        ...state,
        items: action.response.printers,
      };
    case GET_PRINTERS_ERROR:
      return {
        ...state,
        error: action.error.toString(),
      };
    case GET_VERSIONS_SUCCESS:
      return {
        ...state,
        versions: action.response,
      };
    case ADD_PRINTER_REQUESTING:
      return {
        ...state,
        addModalLoading: true,
      };
    case ADD_PRINTER_SUCCESS:
      return {
        ...state,
        addModalVisible: false,
        addModalLoading: false,
      };
    case ADD_PRINTER_ERROR:
      return {
        ...state,
        addModalVisible: false,
        addModalLoading: false,
        error: action.error.toString(),
      };
    case ADD_PRINTER_MODAL_SHOW:
      return {
        ...state,
        addModalVisible: true,
      };
    case ADD_PRINTER_MODAL_HIDE:
      return {
        ...state,
        addModalVisible: false,
      };
    default:
      return state;
  }
}

function toast(state = { message: "", errorMessage: "" }, action) {
  switch (action.type) {
    case REMOVE_PRINTER_SUCCESS:
      return {
        ...state,
        message: "Printer successfully removed",
        errorMessage: "",
      };
    case REMOVE_PRINTER_ERROR:
      return {
        ...state,
        message: "",
        errorMessage: action.error.toString(),
      };
    case AUTHENTICATION_ERROR:
    case GET_VERSIONS_ERROR:
      return {
        ...state,
        errorMessage: action.error.message,
      };
    default:
      return state;
  }
}

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    printerList,
    authentication,
    toast,
  });

export default createRootReducer;
