import React from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { Switch, Route } from "react-router-dom";
import { history } from "../../store";

import { connect } from "react-redux";
import { Row, Col, Layout, Alert } from "antd";
import PrivateRoute from "../PrivateRoute";
import Topnav from "../Topnav";
import Printers from "../Printers";
import Login from "../Login";
import "./App.css";

const { Content } = Layout;

class App extends React.Component {
  static propTypes = {
    message: PropTypes.string,
    errorMessage: PropTypes.string,
  };

  render() {
    return (
      <div className="App">
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/">
              <div>
                {this.props.message !== "" && (
                  <Alert message={this.props.message} closable />
                )}
                {this.props.errorMessage !== "" && (
                  <Alert
                    message={this.props.errorMessage}
                    type="error"
                    closable
                    onClose={() => {}}
                  />
                )}
              </div>
              <Login />
            </Route>
            <Route path="/*">
              <Row>
                <Col span={24}>
                  <Topnav />
                </Col>
              </Row>
              <Content className="site-content">
                <div>
                  {/* FIXME: Spaghetti everwhere */}
                  {this.props.message !== "" && (
                    <Alert message={this.props.message} closable />
                  )}
                  {this.props.errorMessage !== "" && (
                    <Alert
                      message={this.props.errorMessage}
                      type="error"
                      closable
                      onClose={() => {}}
                    />
                  )}
                </div>
                <Row>
                  <Col span={24}>
                    <Switch>
                      <PrivateRoute
                        key="dentaform-listing"
                        path="/dentaform"
                        component={Printers.List}
                        printerType="dentaform"
                      />
                      <PrivateRoute
                        key="velox-listing"
                        path="/velox"
                        component={Printers.List}
                        printerType="velox"
                      />
                    </Switch>
                  </Col>
                </Row>
              </Content>
            </Route>
          </Switch>
        </ConnectedRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.toast.message,
  errorMessage: state.toast.errorMessage,
});

export default connect(mapStateToProps, null)(App);
