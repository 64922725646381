import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Form, Input, Button, Layout, Row, Col } from "antd";
import {
  AUTHENTICATION_REQUESTING,
  GOOGLE_AUTH_REQUESTING,
  AUTHENTICATION_ERROR,
} from "../../constants";
import structologo from "./structologo.png";
import { GoogleLogin } from "react-google-login";
const { Content } = Layout;

class Login extends React.Component {
  static propTypes = {
    onFinish: PropTypes.func,
    messages: PropTypes.array,
    onGoogleLoginSuccess: PropTypes.func,
    onGoogleLoginFailure: PropTypes.func,
    authentication: PropTypes.shape({
      authenticated: PropTypes.bool,
      requesting: PropTypes.bool,
      successful: PropTypes.bool,
    }),
  };

  render() {
    if (this.props.authentication.authenticated) {
      return <Redirect to="/dentaform" />;
    }
    return (
      <Content className="site-content">
        <Row justify="center">
          <Col span={6}>
            <img
              style={{ width: "100%" }}
              src={structologo}
              alt="structologo"
            />
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <h2>Scratch</h2>
          </Col>
        </Row>
        <p></p>
        <Row justify="center">
          <Col md={8}>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={this.props.onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  disabled={this.props.authentication.requesting}
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row justify="center">
          <GoogleLogin
            buttonText="Sign in with Structo3D Google Account"
            clientId="855532613550-b8mabfjub3lifp6mp2i53c627avg3dtr.apps.googleusercontent.com"
            onSuccess={this.props.onGoogleLoginSuccess}
            onFailure={this.props.onGoogleLoginFailure}
          ></GoogleLogin>
        </Row>
      </Content>
    );
  }
}

function onGoogleLoginSuccess(values) {
  return {
    type: GOOGLE_AUTH_REQUESTING,
    accessToken: values.accessToken,
  };
}
function onGoogleLoginFailure(err) {
  return {
    type: AUTHENTICATION_ERROR,
    error: {
      message: err.error,
    },
  };
}

function onFinish(values) {
  return {
    type: AUTHENTICATION_REQUESTING,
    values: values,
  };
}

function onFinishFailed(errorInfo) {
  console.log("Failed:", errorInfo);
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { onFinish, onGoogleLoginSuccess, onGoogleLoginFailure },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
