import React from "react";
import PropTypes from "prop-types";
import { Menu } from "antd";
import {
  PrinterFilled,
  PoweroffOutlined,
  BuildOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AUTHENTICATION_LOGOUT } from "../../constants";
const { SubMenu } = Menu;

class Topnav extends React.Component {
  static propTypes = {
    current: PropTypes.string,
    handleClick: PropTypes.func,
  };

  render() {
    return (
      <Menu
        selectedKeys={[this.props.current]}
        mode="horizontal"
        onClick={this.props.handleClick}
      >
        <Menu.Item key="dentaform">
          <Link to="/dentaform">
            <PrinterFilled />
            Dentaform
          </Link>
        </Menu.Item>

        <Menu.Item key="velox">
          <Link to="/velox">
            <PrinterFilled />
            Velox
          </Link>
        </Menu.Item>

        <SubMenu icon={<BuildOutlined />} title={`Stage - Staging`}>
          <Menu.Item key="setting:staging">Staging</Menu.Item>
          <Menu.Item
            key="setting:production"
            onClick={(e) => alert("Production is still WIP")}
          >
            Production
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="logout">
          <PoweroffOutlined />
          Logout
        </Menu.Item>
      </Menu>
    );
  }
}

function handleClick(e) {
  if (e.key === "logout")
    return {
      type: AUTHENTICATION_LOGOUT,
    };

  return {
    type: "noaction",
  };
}

const mapStateToProps = (state) => ({
  current: state.router.location.pathname,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ handleClick }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Topnav);
