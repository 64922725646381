import { all, takeLatest, put, call, select } from "redux-saga/effects";
import {
  AUTHENTICATION_REQUESTING,
  AUTHENTICATION_ERROR,
  AUTHENTICATION_SUCCESS,
  GET_PRINTERS_REQUESTING,
  GET_PRINTERS_SUCCESS,
  GET_PRINTERS_ERROR,
  ADD_PRINTER_REQUESTING,
  ADD_PRINTER_ERROR,
  ADD_PRINTER_SUCCESS,
  REMOVE_PRINTER_REQUESTING,
  REMOVE_PRINTER_SUCCESS,
  REMOVE_PRINTER_ERROR,
  GOOGLE_AUTH_REQUESTING,
  GET_VERSIONS_REQUESTING,
  GET_VERSIONS_SUCCESS,
  GET_VERSIONS_ERROR,
} from "./constants";

const getToken = (state) => state.authentication.token;

// ------------------------------
// Login methods
async function loginAPI(email, password) {
  const response = await fetch(
    `${process.env.REACT_APP_STARNOSE_STAGING_URL}/auth/login`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    }
  );

  const respBody = await response.json();
  if (!response.ok) {
    throw new Error(respBody.message);
  }
  return respBody;
}

function* authFlow(action) {
  try {
    const { email, password } = action.values;
    const response = yield call(loginAPI, email, password);
    yield put({ type: AUTHENTICATION_SUCCESS, response });
  } catch (error) {
    yield put({ type: AUTHENTICATION_ERROR, error });
  }
}

function* authenticationWatcher() {
  yield takeLatest(AUTHENTICATION_REQUESTING, authFlow);
}

// ------------------------------
// Google Login methods
async function googleLoginAPI(accessToken) {
  const response = await fetch(
    `${process.env.REACT_APP_STARNOSE_STAGING_URL}/auth/oauth/google`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ accessToken }),
    }
  );

  const respBody = await response.json();
  if (!response.ok) {
    throw new Error(respBody.message);
  }
  return respBody;
}

function* googleLoginFlow(action) {
  try {
    const response = yield call(googleLoginAPI, action.accessToken);
    yield put({ type: AUTHENTICATION_SUCCESS, response });
  } catch (error) {
    yield put({ type: AUTHENTICATION_ERROR, error });
  }
}

function* googleLoginWatcher() {
  yield takeLatest(GOOGLE_AUTH_REQUESTING, googleLoginFlow);
}

// ------------------------------
// Get Printer methods
async function getPrinterAPI(printerType, token) {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/v1/scratchprinters?printerType=${printerType}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const respBody = await response.json();
  if (!response.ok) {
    throw new Error(respBody.error);
  }
  return respBody;
}

function* getPrinters(action) {
  try {
    const response = yield call(
      getPrinterAPI,
      action.printerType,
      yield select(getToken)
    );
    yield put({ type: GET_PRINTERS_SUCCESS, response: response });
  } catch (error) {
    yield put({ type: GET_PRINTERS_ERROR, error });
  }
}

function* getPrintersWatcher() {
  yield takeLatest(GET_PRINTERS_REQUESTING, getPrinters);
}

// ------------------------------
// Get Versions Methods
async function getVersionsAPI(printerType, token) {
  let url = "";
  switch (printerType) {
    case "dentaform":
      url = `${process.env.REACT_APP_STARNOSE_STAGING_URL}/api/softwareversions`;
      break;
    case "velox":
      url = `${process.env.REACT_APP_RAINDROP_COURIER_STAGING_URL}/velox/retrieve-software-versions/v1`;
      break;
    default:
      break;
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const respBody = await response.json();
  if (!response.ok) {
    throw new Error(respBody.error);
  }

  switch (printerType) {
    case "dentaform":
      return [
        ...new Set(
          respBody
            .filter((version) => version.type.name === "printer-ui")
            .map((x) => x.version)
        ),
      ];
    case "velox":
      return [
        ...new Set(
          respBody.map(
            (x) => `${x.version.major}.${x.version.minor}.${x.version.patch}`
          )
        ),
      ];
    default:
      return [];
  }
}

function* getVersions(action) {
  try {
    const { printerType } = action;
    const response = yield call(
      getVersionsAPI,
      printerType,
      yield select(getToken)
    );
    yield put({ type: GET_VERSIONS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_VERSIONS_ERROR, error });
  }
}

function* getVersionsWatcher() {
  yield takeLatest(GET_VERSIONS_REQUESTING, getVersions);
}

// ------------------------------
// Add Printer Methods
async function addPrinterAPI(printerType, softwareVersion, token) {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/v1/scratchprinters`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ softwareVersion, printerType }),
    }
  );

  const respBody = await response.json();
  if (!response.ok) {
    throw new Error(respBody.error);
  }
  return respBody;
}

function* addPrinter(action) {
  try {
    const response = yield call(
      addPrinterAPI,
      action.printerType,
      action.values.softwareVersion,
      yield select(getToken)
    );
    yield put({ type: ADD_PRINTER_SUCCESS, response });
    yield getPrinters(action);
  } catch (error) {
    yield put({ type: ADD_PRINTER_ERROR, error });
  }
}

function* addPrinterWatcher() {
  yield takeLatest(ADD_PRINTER_REQUESTING, addPrinter);
}

// ------------------------------
// Remove Printer Methods
async function removePrinterAPI(printerType, name, token) {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/v1/scratchprinters/${name}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const respBody = await response.json();
  if (!response.ok) {
    throw new Error(respBody.error);
  }
  return respBody;
}

function* removePrinter(action) {
  try {
    const { printerType, name } = action;
    const response = yield call(
      removePrinterAPI,
      printerType,
      name,
      yield select(getToken)
    );
    yield put({ type: REMOVE_PRINTER_SUCCESS, response });
    yield getPrinters(action);
  } catch (error) {
    yield put({ type: REMOVE_PRINTER_ERROR, error });
  }
}

function* removePrinterWatcher() {
  yield takeLatest(REMOVE_PRINTER_REQUESTING, removePrinter);
}

export default function* rootSaga() {
  yield all([
    authenticationWatcher(),
    googleLoginWatcher(),
    getPrintersWatcher(),
    addPrinterWatcher(),
    removePrinterWatcher(),
    getVersionsWatcher(),
  ]);
}
