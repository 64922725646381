import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import createRootReducer from "./reducers";
import rootSaga from "./sagas";

function saveState(state) {
  try {
    const serializedState = JSON.stringify(state);
    return localStorage.setItem("scratchState", serializedState);
  } catch (err) {
    console.error(err);
  }
}

function loadState() {
  try {
    const serializedState = localStorage.getItem("scratchState");
    if (serializedState === null) return undefined;

    const parsedState = JSON.parse(serializedState);
    if (parsedState.authentication) {
      //super cheap method to unset values
      parsedState.authentication.requesting = false;
      parsedState.authentication.errors = [];
    }

    return parsedState;
  } catch (err) {
    return undefined;
  }
}

// Create browser history
const history = createBrowserHistory();

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

function configureStore(preloadedState) {
  return createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  );
}

const store = configureStore(loadState());
store.subscribe(() => {
  saveState({
    authentication: store.getState().authentication,
  });
});

// then run the saga
sagaMiddleware.run(rootSaga);

export { store, sagaMiddleware, history };
